@import "../../../styles/colors.scss";
@import "../../../styles/constants.scss";

.vehicleSearchPanel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../public/assets/images/home-page-background.jpg);
}

.opacityBack {
  background-color: rgba($color: $black-color, $alpha: 0.5);

  .header {
    margin-top: 160px;

    text-align: center;
    font-weight: 400;
  }

  .findCar {
    margin-top: 86px;
  }
}

.evGuide {
  // padding-top: 114px;
  padding-bottom: 114px;

  .evGuideHeader {
    text-align: center;
    color: $white-color;
    font-weight: 400;
  }

  .evGuideBtn {
    display: block;

    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    width: 300px;
    height: 75px;

    font-size: 24px;

    color: $white-color;
  }
}

@media screen and (max-width: $small-screen) {
  .opacityBack {
    .header {
      margin-top: 140px;
    }

    .findCar {
      margin-top: 70px;
    }
  }
  
  .evGuide {
    // padding-top: 100px;
    padding-bottom: 100px;

    .evGuideBtn {
      width: 260px;
      height: 60px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 900px) {
  .evGuide {
    .evGuideHeader {
      max-width: 500px;
      margin: auto;
    }
  }
}

@media screen and (max-width: $mobile-screen) {
  .opacityBack {
    .header {
      margin-top: 90px;
      font-size: 32px;
      line-height: 44px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    .findCar {
      margin-top: 86px;
    }
  }

  .evGuide {
    // padding-top: 80px;
    padding-bottom: 80px;

    .evGuideHeader {
      max-width: 400px;
    }
    
    .evGuideBtn {
      margin-top: 24px;
      width: 230px;
      height: 54px;
      font-size: 18px;
    }
  }
}

.vehicleSearchPanelBoi {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../../public/assets/images/home-page-background.jpg);
  margin-top: 200px;
}

.opacityBackBoi {
  background-color: $nevo-boi-light-color;

  .headerBoi {
    color: $nevo-boi-blue-color !important;
    text-align: center;
    font-family: "BoiFont";
    font-size: 80px;
    font-weight: 400;
  }

  .findCarBoi {
    margin-top: 50px;
  }
}

.evGuideBoi {
  // padding-top: 114px;
  padding-bottom: 114px;

  .evGuideHeaderBoi {
    text-align: center;
    color: $white-color;
    font-weight: 400;
  }

  .evGuideBtnBoi {
    display: block;

    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

    width: 300px;
    height: 75px;

    font-size: 24px;

    color: $white-color;
  }
}

@media screen and (max-width: $small-screen) {
  .opacityBackBoi {
    .headerBoi {
      font-size: 52px;
      color: $nevo-boi-blue-color !important;
    }

    .findCarBoi {
      margin-top: 70px;
    }
  }
  
  .evGuideBoi {
    // padding-top: 100px;
    padding-bottom: 100px;

    .evGuideBtnBoi {
      width: 260px;
      height: 60px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 900px) {
  .evGuideBoi {
    .evGuideHeaderBoi {
      max-width: 500px;
      margin: auto;
    }
  }
}

@media screen and (max-width: $mobile-screen) {
  .opacityBackBoi {
    .headerBoi {
      font-size: 52px;
      line-height: 44px;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    .findCarBoi {
      margin-top: 20px;
    }
  }

  .evGuideBoi {
    // padding-top: 80px;
    padding-bottom: 80px;

    .evGuideHeaderBoi {
      max-width: 400px;
    }
    
    .evGuideBtnBoi {
      margin-top: 24px;
      width: 230px;
      height: 54px;
      font-size: 18px;
    }
  }
}