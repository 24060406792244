@import "./colors.scss";
@import "./constants.scss";

// @keyframes MoveUpDown {
//   0%, 100% {
//     transform: translateY(-5px);
//   }
//   50% {
//     transform: translateY(20px);
//   }
// }

.homePage {
  margin-top: 0px;
  padding-bottom: 0;
  overflow: hidden;

  .slide {
    width: 100vw;
    height: 100vh;

    h1 {
      text-align: center;
      font-size: 80px;
      line-height: 70px;
      
      @media screen and (max-width: $small-screen) {
        font-size: 60px;
      }
      @media screen and (max-width: $mobile-screen) {
        font-size: 42px;
      }
    }

    button {
      width: 375px;
      max-width: 100%;
      height: 70px;

      font-size: 24px;
      border-radius: 100px;

      @media screen and (max-width: $small-screen) {
        width: 300px;
        height: 60px;
        font-size: 18px;
      }
      @media screen and (max-width: $mobile-screen) {
        width: 100%;
        height: 55px;
        max-width: 300px;
      }
    }
  }

  .greyBackground {
    background: linear-gradient(180deg, $light-grey-color 0%, $grey-gradient-color 100%);
  }
  // .intro {
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;

  //   min-height: 600px;

  //   .content {
  //     padding: 70.5px 10% 70.5px 10%;


  //     width: 100%;
  //     height: 100%;
  //     background-color: rgba(#000000, 0.65);

  //     display: flex;
  //     flex-flow: column nowrap;
  //     justify-content: space-between;
  //     align-items: center;

  //     @media screen and (max-width: $small-screen) {
  //       padding: 60px 10% 60px 10%;
  //     }

  //     @media screen and (max-width: $mobile-screen) {
  //       padding: 60px 0 60px 0;
  //     }

  //     .invisibleBox {
  //       height: 89px;
  //     }

  //     .headers {
  //       h1, h2 {
  //         text-align: center;
  //         color: $white-color; 
  //       }
  //       h1 {
  //         font-size: 78px !important;
  //         line-height: unset !important;
  //       }
  //       h2 {
  //         margin-top: 2px;
  //         font-size: 57px;
  //       }

  //       @media screen and (max-width: 745px) {
  //         h1 {
  //           font-size: 10.5vw !important;
  //         }
  //         h2 {
  //           font-size: 7.65vw;
  //         }
  //       }

  //       @media screen and (max-width: $mobile-screen) {
  //         h1 {
  //           font-size: 11.8vw !important;
  //         }
  //         h2 {
  //           font-size: 8.63vw;
  //         }
  //       }
  //     }

  //     .seeMore {
  //       cursor: pointer;
  //       user-select: none;

  //       display: flex;
  //       flex-flow: column nowrap;
  //       align-items: center;

  //       p {
  //         color: $white-color;
  //         font-size: 24px;
  //         font-weight: 500;
  //       }

  //       img {
  //         width: 38.5px;
  //         margin-top: 28px;
  //         animation: MoveUpDown 1.5s linear infinite;
  //       }
  //     }
  //   }
  // }

  .evBenefits {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    background-color: $black-color;
    
    min-height: 1030px;

    @media screen and (max-width: $small-screen) {
      min-height: 810px;
    }

    @media screen and (max-width: 900px) {
      min-height: 1225px;
    }

    @media screen and (max-width: $mobile-screen) {
      height: unset;
      min-height: unset;
      
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .contentHolder {
      width: 100%;

      h1 {
        color: $white-color;

        margin-bottom: 134px;
        
        @media screen and (max-height: 1080px) {
          margin-bottom: 12.39vh;
        }
        @media screen and (max-width: $small-screen) {
          margin-bottom: 10vh !important;
        }
        @media screen and (max-width: 900px) {
          margin-bottom: 8vh !important;
        }
        @media screen and (max-width: $mobile-screen) {
          margin-bottom: 5vh;
        }
      }
    }
  }

  .evBenefitsBoi {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    background-color: $nevo-boi-light-color;
    
    min-height: 1030px;

    @media screen and (max-width: $small-screen) {
      min-height: 810px;
    }

    @media screen and (max-width: 900px) {
      min-height: 1225px;
    }

    @media screen and (max-width: $mobile-screen) {
      height: unset;
      min-height: unset;
      
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .contentHolder {
      width: 100%;

      h1 {
        color: $black-color;

        margin-bottom: 134px;
        
        @media screen and (max-height: 1080px) {
          margin-bottom: 12.39vh;
        }
        @media screen and (max-width: $small-screen) {
          margin-bottom: 10vh !important;
        }
        @media screen and (max-width: 900px) {
          margin-bottom: 8vh !important;
        }
        @media screen and (max-width: $mobile-screen) {
          margin-bottom: 5vh;
        }
      }
    }
  }

  .helpAdviceSection {
    height: unset;
    min-height: unset;
    padding-top: 75px;
    padding-bottom: 75px;
    .adBanner {
      margin-top: 75px;
    }
    @media screen and (max-width: $mobile-screen) {
      padding-top: 50px;
      padding-bottom: 50px;
      .adBanner {
        margin-top: 50px;
      }
    }    
  }

  .helpAdviceSectionBoi {
    height: unset;
    min-height: unset;
    padding-top: 75px;
    padding-bottom: 75px;
    .adBanner {
      margin-top: 75px;
    }
    @media screen and (max-width: $mobile-screen) {
      padding-top: 50px;
      padding-bottom: 50px;
      .adBanner {
        margin-top: 50px;
      }
    }    
  }

  .nextCar {
    display: flex;
    align-items: center;

    min-height: 930px;

    @media screen and (max-width: $small-screen) {
      min-height: 730px;
    }

    @media screen and (max-width: 636px) {
      min-height: 750px;
    }
  }

  .nevoCharging {
    display: flex;
    align-items: center;

    background-color: $black-color;

    min-height: 960px;

    @media screen and (max-width: $small-screen) {
      min-height: 720px;
    }
    @media screen and (max-width: 1100px) {
      min-height: 950px;

      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (max-width: 610px) {
      min-height: 1025px;
    }
    @media screen and (max-width: $mobile-screen) {
      min-height: 850px;
    }
  }

  .planJourney {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    min-height: 835px;

    @media screen and (max-width: $small-screen) {
      min-height: 700px;
    }
    @media screen and (max-width: 1150px) {
      height: unset;
      min-height: unset;
    }
  }

  .nevoPartners {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 975px;

    @media screen and (max-width: $small-screen) {
      min-height: 700px;
    }
    @media screen and (max-width: 900px) {
      min-height: 1070px;
    }
    @media screen and (max-width: $mobile-screen) {
      min-height: 860px;
    }
  }
  

  .evStatistics {
    min-height: 900px;

    @media screen and (max-width: $small-screen) {
      min-height: 600px;
    }
    @media screen and (max-width: 767px) {
      height: unset;
    }
  }

  .askNevo {
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 900px;

    @media screen and (max-width: $small-screen) {
      min-height: 710px;
    }
    @media screen and (max-width: 1024px) {
      min-height: 900px;
    }
    @media screen and (max-width: $mobile-screen) {
      min-height: 750px;
    }
  }
  
  .tools {
    background-color: $ev-benefits-grey !important; 
  }

  .toolsBoi {
    background-color: $white-color;
  }

  .servicesPanel {
    background-color: $white-color;

    .contentHolder {
      .servicesHeader {
        color: $black-color;
      }
    }
    

    @media screen and (max-width: 900px) {
      min-height: 900px;
    }
    @media screen and (max-width: $mobile-screen) {
      height: unset;
      min-height: unset;

      .contentHolder {
        .servicesHeader {
          margin-bottom: 5vh !important;
        }
      }
    }
  }
}